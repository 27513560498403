import { SwiperWrapperComponent } from "@components/Swiper/SwiperWrapperComponent";
import { Navigation, Autoplay } from "swiper";
import { SwiperSlide } from "swiper/react";
import { Icon } from "@iconify/react";
import { getBlogCategorySlug } from "@/integrations/prodesa/util";
import Picture from "@ui/general/Picture";
import { url_image } from "@api/ProyectoApi";
import { Button } from "@ui/general/BtnComponent";
import { useApi } from "@context/ApiContext";
import { TitleH2Component } from "@components/TitleH2Component";
import { ParagraphComponent } from "@components/ParagraphComponent";
import { TitleH6Component } from "@components/TitleH6Component";
import StyleGeneral from "@scss/general.module.scss";

export default function News({ blogs = [], categories = [] }) {

  const { getTextoBigByKey, getTranslation } = useApi();
  
  if (!blogs || blogs.length < 1) {
    return <></>;
  }
  
  return (
    <section>
      <div className="bg-gris-claro py-3 py-lg-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-8 order-2 order-md-1">
              <div className="d-flex justify-content-center align-items-center">
                <SwiperWrapperComponent
                  id={"noticias"}
                  modules={[Navigation, Autoplay]}
                  navigation={{
                    nextEl: "#custom-next-btn-b",
                    prevEl: "#custom-prev-btn-b",
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    576: {
                      slidesPerView: 2,
                      spaceBetween: 15,
                    },
                    992: {
                      slidesPerView: 2.2,
                      spaceBetween: 15,
                    },
                  }}
                  speed={2000}
                  autoplay={{ delay: 8000, disableOnInteraction: true }}
                  lazy={true}
                >
                  {blogs.map((item, index) => (
                    <SwiperSlide key={index} className="h-auto p-1">
                      <div
                        className={`m-1 bg-white ${StyleGeneral.borderNaranja} ${StyleGeneral.borderRadius10} ${StyleGeneral.containerCardNoticias}`}
                      >
                        <a
                          href={`/blog/${getBlogCategorySlug(
                            item,
                            categories
                          )}/${item?.slug ?? item?.id}`}
                          className={`${StyleGeneral.Link_red} fw500 WorkSans `}
                          onClick={() => {}}
                          aria-label="Link to blog"
                          title="Link to blog"
                        >
                          <div className="">
                            <Picture
                              src={url_image + item?.imagen?.image}
                              alt="Prodesa"
                              styleGroup="w-100"
                              height={200}
                              width={350}
                            />
                          </div>
                          <div className=" p-2 p-md-3">
                            <TitleH6Component
                              styleGroup={`fw-bold color-gris tama1 ${StyleGeneral.text_2line}`}
                            >
                              {" "}
                              {getTranslation(item, "titulo")}
                            </TitleH6Component>
                          </div>
                        </a>
                      </div>
                    </SwiperSlide>
                  ))}
                </SwiperWrapperComponent>
              </div>
              <div className="d-flex  justify-content-center justify-content-md-end align-items-center">
                <button
                  id="custom-prev-btn-b"
                  className={` ${StyleGeneral.smallArrow}`}
                >
                  <Icon icon="ep:arrow-left" />
                </button>
                <button
                  id="custom-next-btn-b"
                  className={` ${StyleGeneral.smallArrow}`}
                >
                  <Icon icon="ep:arrow-right" />
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center justify-content-lg-start order-1 order-md-2">
              <div className="p-3 p-lg-4">
                <TitleH2Component styleGroup="text-center text-lg-start color-naranja mb-3">
                  {getTextoBigByKey("sec-nuestra-noticias")?.titulo}
                </TitleH2Component>
                <ParagraphComponent
                  styleGroup="text-center text-lg-start color-gris mb-3"
                  html={getTextoBigByKey("sec-nuestra-noticias")?.valor}
                ></ParagraphComponent>
                <div className="text-center text-lg-start">
                  <Button
                    id="btn_nuestra_noticias"
                    to={getTextoBigByKey("sec-nuestra-noticias")?.link}
                  >
                    {getTextoBigByKey("sec-nuestra-noticias")?.boton}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
