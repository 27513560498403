"use client";

import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { getImageByKey } from "@integrations/prodesa/api";

async function getData (imageKey) {
  const data = await getImageByKey(imageKey, false, 600);
  return data;
};

const getTranslation = (data) => {
  let translation = data ? data.translations[0] : null;
  return translation;
};

export default function Popup({ imageKey }) {
  const [showPopup, setShowPopup] = useState(false);
  const { data, error } = useSWR(imageKey, getData);
  const [translation, setTranslation]= useState(null);

  useEffect(() => {
    if (data) {
      const translationData = getTranslation(data);
      setTranslation(translationData);
      if(data?.visible && translationData?.link){
        setShowPopup(true);
      }
    }
  }, [data]);

  if (error && !data) {
    return <></>;
  }

  const closePopup = () => {
    setShowPopup(false);
  };

  const redirectToRapicredit = () => {
    const translation = getTranslation(data);
    window.open(translation.link, "_blank");
    closePopup();
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };

  return (
    <>
      {showPopup && (
        <div
          style={styles.popup_container}
          onClick={handleBackgroundClick}
          className="popup-container"
        >
          <div style={styles.popup} className="popup">
            <img
              className="popup-image"
              src={data.imageLink}
              alt={translation?.titulo}
              style={styles.image}
              onClick={redirectToRapicredit}
            />
            <button
              style={styles.close_button}
              onClick={closePopup}
              className="popup-close-button"
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  popup_container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100001, // Render above other content
  },
  popup: {
    position: "relative",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    margin: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: "100%",
    maxHeight: "550px",
    objectFit: "cover",
    borderRadius: "8px",
    cursor: "pointer",
  },
  close_button: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "rgb(189, 59, 23)",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
};
