"use client";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";

import ClientWrapper from "@/ClienteWrapper";
import { useApi } from "@context/ApiContext";
import { TitleH2Component } from "@components/TitleH2Component";
import { ContolInputComponent } from "@components/ControlInputComponent";
import { HiddenH1Component } from "@components/HiddenH1Component";
import Projects from "@ui/home/Projects";
import Invest from "@ui/home/Invest";
import Cities from "@ui/home/Cities";
import News from "@ui/home/News";
import Store from "@ui/general/Store";
import InstagramEmbedded from "@ui/home/InstagramEmbeddeb";
import StyleGeneral from "@scss/general.module.scss";
import StyleHome from "@scss/home.module.scss";

export default function Page(props) {
  return (
    <ClientWrapper>
      <HomePage {...props} />
    </ClientWrapper>
  );
}

function HomePage({
  types,
  noticias,
  invierta,
  blogCategories,
  cities: ciudades,
  projectsNewReleases: proyectosData,
  projectsImmediateDelivery: proyectosInmData,
}) {
  const { getTextoByKey, getTranslation } = useApi();
  const [typeTitle, setTypeTitle] = useState(1);
  const options = types?.map((item, index) => {
    return {
      label: getTranslation(item, "titulo"),
      value: index + 1,
    };
  });

  const [tipoSel, setTipoSel] = useState(1);

  const verifyradioStatus = (name, id) => {
    return name == "lanzamiento" && id == tipoSel;
  };

  const newReleaseTitle = getTextoByKey("titulo-lanzamientos")?.valor;

  useEffect(() => {
    const option = options?.find((op) => op.value == tipoSel);
    setTypeTitle(option?.label);
    if (tipoSel == 1) {
      setTypeTitle(newReleaseTitle);
    }
  }, [tipoSel, options, newReleaseTitle]);

  const changeTipo = (e) => {
    const valor = e.target.value;
    setTipoSel(valor);
  };

  return (
      <>
        <section>
          <div
            className={` pt-4 pt-sm-5 pt-md-5 ${StyleGeneral.controlesHome}`}
          >
            <ContolInputComponent
              size="regular"
              type="radio"
              onChangeValue={changeTipo}
              verifyCheckStatus={verifyradioStatus}
              options={options}
              name="lanzamiento"
              styleGroup={`${StyleHome.wpcontrolv2} mb-0 p-0 p-sm-2 p-md-auto me-1 me-sm-2 me-xl-5 fw400`}
              styleTexto="fw700"
              controlSpecial
            />
          </div>
        </section>

        <div className="py-2">
          <HiddenH1Component styleGroup="text-center">
            {getTextoByKey("titulo-constructora-prodesa")?.valor}
          </HiddenH1Component>
          <TitleH2Component styleGroup="roboto text-center color-gris fw700">
            {typeTitle}
          </TitleH2Component>
        </div>

        <Projects projects={proyectosData} show={tipoSel == 1} />
        <Projects projects={proyectosInmData} show={tipoSel == 3} />
        <Cities cities={ciudades} show={tipoSel == 2} />
        <Invest data={invierta} show={tipoSel == 4} />
        <News blogs={noticias} categories={blogCategories} />
        <Store />
        <InstagramEmbedded />
      </>
  );
}
