import { Icon } from "@iconify/react";
import { Navigation, Autoplay } from "swiper";
import { SwiperSlide } from "swiper/react";
import { url_image } from "@api/ProyectoApi";
import { SwiperWrapperComponent } from "@components/Swiper/SwiperWrapperComponent";
import { UbicationCard } from "@ui/general/UbicationCard";
import StyleGeneral from "@scss/general.module.scss";

export default function Cities({ cities = [], show = false }) {
  if (!cities || cities.length < 1 || !show) {
    return <></>;
  }

  return (
    <section>
      <div>
        <div className="container">
          <div className="d-flex justify-content-center align-items-center pb-4">
            <button id={"city-prev-btn"} className={` ${StyleGeneral.arrow}`}>
              <Icon icon="ep:arrow-left" />
            </button>
            <SwiperWrapperComponent
              id={"donde-quieres-vivir"}
              modules={[Navigation, Autoplay]}
              navigation={{
                nextEl: "#city-next-btn",
                prevEl: "#city-prev-btn",
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                992: {
                  slidesPerView: 5,
                  spaceBetween: 25,
                },
              }}
              speed={1000}
              autoplay={{ delay: 8000, disableOnInteraction: true }}
              lazy={true}
            >
              {cities.map((item, index) => (
                <SwiperSlide key={index}>
                  <UbicationCard
                    ciudad={item.nombre}
                    id={item.id}
                    slug={item.slug}
                    img={url_image + item?.image?.image}
                  />
                </SwiperSlide>
              ))}
            </SwiperWrapperComponent>
            <button id={"city-next-btn"} className={` ${StyleGeneral.arrow}`}>
              <Icon icon="ep:arrow-right" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
