import { Metadata } from "next";
import { getVariables } from "@/config";
import { SeoTranslationType, SeoType, TextoTranslationType, TextoType } from "@/integrations/prodesa/definitions";

const { URL_IMAGES } = getVariables();

export function generateImageLink(imageName?: string): string {
    if (imageName) return `${URL_IMAGES}${imageName}`;
    return '';
}

export function getTranslation(translations: any, locale: string = 'es'): any {
    try {
        let translation = translations?.find(({ local }: { local: string }) => local === locale) ?? null;
        if (!translation && Array.isArray(translations) && translations.length > 0) {
            translation = translations[0];
        }
        return translation;
    } catch (error) {

    }
    return null;
}

export function getTranslationAttributeValue(text: any, attribute: string, locale: string = 'es') {
    if (text) {
        const translation = getTranslation(text?.translations, locale);
        if (translation) {
            return translation?.[attribute] ?? '';
        }
    }
    return '';
}

export function getTextByKey(texts: TextoType[], key: string): TextoType | null {
    return texts?.find(({ llave }) => llave === key) ?? null;
}

export function generateSeoData(
    { title = 'Prodesa', description = '', keywords = 'prodesa, proyectos, vivienda', canonical = '/' }: { title?: string, description?: string, keywords?: string, canonical?: string },
    seo?: SeoType | null
): Metadata {
    const seoTranslation: SeoTranslationType = getTranslation(seo?.translations);
    let titleSeo = seoTranslation?.titulo ?? title;
    let descriptionSeo = seoTranslation?.descripcion ?? description;
    let keywordsSeo = seoTranslation?.keywords ?? keywords;
    const canonicalSeo = seoTranslation?.canonical ?? canonical;
    return {
        title: titleSeo,
        description: descriptionSeo,
        keywords: keywordsSeo,
        alternates: {
            canonical: canonicalSeo
        },
        openGraph: {
            title: titleSeo,
            description: descriptionSeo
        },
        twitter: {
            title: titleSeo,
            description: descriptionSeo
        }
    }
}

export function getBlogCategorySlug(blog: any, categories: any[]) {
    let blogCategorySlug = blog?.categoriasBlog[0];
    if (blogCategorySlug && categories && Array.isArray(categories) && categories.length > 0) {
        const category = categories.find((category) => category.id == blogCategorySlug);
        blogCategorySlug = category?.slug ?? category.id;
    }
    return blogCategorySlug ?? 'category';
}


export function getTextTranstalation(texts: TextoType[], textKey: string): TextoTranslationType {
    const text = getTextByKey(texts, textKey);
    const textTranslation = getTranslation(text?.translations);
    return textTranslation;
}