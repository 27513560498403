import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(ssr)/page.csr.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/general/Popup.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/home/Banner.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/home/BannerSkeleton.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/home/BodySkeleton.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/ui/home/SearchBar.jsx");
