import Picture from "@ui/general/Picture";
import { TitleH4Component } from "@components/TitleH4Component";
import { scrollToTop } from "@components/GlobalFunction";
import Style from "@scss/ubicationProperty.module.scss";
import Link from "next/link";

export function UbicationCard({ ciudad, img, slug }) {
  return (
    <div>
      <div className="text-center">
        <TitleH4Component styleGroup="color-naranja">{ciudad}</TitleH4Component>
      </div>
      <div className={Style.ubicationProperty}>
        <Picture
          src={img}
          alt="Prodesa"
          styleGroup=""
          width={155}
          height={180}
        />
        <Link
          href={`/proyecto-vivienda/${slug}`}
          className={Style.hoverUbi}
          onClick={scrollToTop}
        />
      </div>
    </div>
  );
}
