"use client";
import Search from "@ui/seeker/Search";
import { useEffect, useState } from "react";
import { useForm } from "@hooks/useForm";
import { useApi } from "@context/ApiContext";
import {
  getAreasProyecto,
  getIconosProyecto,
  getpreciosProyectos,
} from "@api/ProyectoApi";
import ClientWrapper from "@/ClienteWrapper";
import StyleGeneral from "@scss/general.module.scss";

export default function SearchBar(props) {
  return (
    <ClientWrapper>
      <SearchComponent {...props} />
    </ClientWrapper>
  );
}
export function SearchComponent({ cities: ciudades }) {
  const { getTextoByKey } = useApi();

  const [FiltroFields, setFilTroFields] = useState({
    habitaciones: 0,
    desde: "",
    hasta: "",
    precioMin: "",
    precioMax: "",
  });
  const [ciudadSel, setCiudadSel] = useState("");
  const [filtrosEnvio, setFiltrosEnvio] = useState("");
  const [errorPrecios, setErrorPrecios] = useState("");
  const [preciosProy, setPrecioProy] = useState({ minimo: 0, maximo: 0 });
  const [areasProy, setAreasProy] = useState({ minimo: 0, maximo: 0 });
  const [habitas, setHabitas] = useState({ minimo: 0, maximo: 0 });
  const { formState, onInputChange: onInputChange_ } = useForm(FiltroFields);

  const onInputChange = (e) => {
    const { name, value } = e.target;

    onInputChange_(e);
    if (name == "ubicacion") {
      let city = ciudades.find((c) => c.slug == value);
      if (city) setCiudadSel(city?.id);
    }
    setFilTroFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    let query = "";
    if (ciudadSel) {
      query = "&ciudad=" + ciudadSel;
    }
    setFilTroFields(() => ({
      precioMax: "",
      precioMin: "",
      desde: "",
      hasta: "",
      habitaciones: 0,
    }));
    Promise.all([
      getpreciosProyectos(query),
      getAreasProyecto("&tipo=2" + query),
      getIconosProyecto("&tipo=1" + query),
    ])

      .then(([preciosData, areasdata, iconosData]) => {
        setPrecioProy(preciosData);
        setAreasProy(areasdata);
        setHabitas(iconosData);
      })
      .catch((error) => {
        console.error("Error al cargar datos:", error);
      });
  }, [ciudadSel]);

  useEffect(() => {
    let queryString = Object.keys(FiltroFields)
      .filter(
        (key) =>
          FiltroFields[key] &&
          key != "ubicacion" &&
          key != "precioMin" &&
          key != "precioMax"
      )
      .map((key) => `${key}=${FiltroFields[key]}`)
      .join("&");

    let preciomin = FiltroFields["precioMin"];
    let preciomax = FiltroFields["precioMax"];
    let precios = true;
    if (preciomin && preciomax) {
      if (parseFloat(preciomin) > parseFloat(preciomax)) {
        precios = false;
      }
    }

    setErrorPrecios("");

    if (precios) {
      if (preciomin || preciomax) {
        queryString +=
          "&" +
          Object.keys(FiltroFields)
            .filter(
              (key) =>
                (FiltroFields[key] && key == "precioMin") || key == "precioMax"
            )
            .map((key) => `${key}=${FiltroFields[key]}`)
            .join("&");
      }
    } else {
      setErrorPrecios(getTextoByKey("error-buscador-precios")?.valor);
    }
    setFiltrosEnvio(queryString);
  }, [FiltroFields]);

  return (
    <div className={`${StyleGeneral.filterHome} sticky-topgeneral`}>
      <Search
        ciudades={ciudades}
        formState={formState}
        onInputChange={onInputChange}
        filtrosEnvio={filtrosEnvio}
        FiltroFields={FiltroFields}
        precios={preciosProy}
        areas={areasProy}
        habitas={habitas}
        errorPrecios={errorPrecios}
      />
    </div>
  );
}
