import { Navigation, Autoplay } from "swiper";
import { SwiperSlide } from "swiper/react";
import { Icon } from "@iconify/react";
import { SwiperWrapperComponent } from "@components/Swiper/SwiperWrapperComponent";
import { PropertyComponent } from "@ui/general/PropertyComponent";
import StyleGeneral from "@scss/general.module.scss";

export default function Projects({ projects, show = false }) {

  if (!projects || !show) {
    return <></>;
  }
  
  return (
    <section>
      <div className="container">
        <div
          className={`d-flex justify-content-center align-items-center pb-4 ${
            projects.length < 3 ? "galeria-videos" : ""
          }`}
        >
          <button
            id="custom-prev-btn"
            className={` ${StyleGeneral.arrow} ${StyleGeneral.arrowizquierda}`}
          >
            <Icon icon="ep:arrow-left" />
          </button>
          <SwiperWrapperComponent
            id={"lanzamientodata"}
            modules={[Navigation, Autoplay]}
            className="w-100"
            navigation={{
              nextEl: "#custom-next-btn",
              prevEl: "#custom-prev-btn",
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 35,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            autoplay={{ delay: 8000, disableOnInteraction: true }}
            lazy={true}
          >
            {projects.map((item, index) => {
              return (
                <SwiperSlide key={index} className="h-auto p-1">
                  <PropertyComponent key={index} proyecto={item} />
                </SwiperSlide>
              );
            })}
          </SwiperWrapperComponent>
          <button
            id="custom-next-btn"
            className={` ${StyleGeneral.arrow} ${StyleGeneral.arrowderecha}`}
          >
            <Icon icon="ep:arrow-right" />
          </button>
        </div>
      </div>
    </section>
  );
}
