import {Swiper, SwiperSlide} from "swiper/react";
import React, {useEffect, useState} from "react";

export const SwiperWrapperComponent = (props) => {

    // Please, just add a proper id, this won't handle anything after a while...
    if (!props.id) {
        console.warn("Swiper Wrapper lacks an ID, please add one")
    }

    const getSwiper = () => {
        return document?.querySelector(`#${props.id}`)?.swiper;
    }

    const validateButtons = () => {
        const swiper = getSwiper();

        if (swiper?.isEnd && props?.navigation?.nextEl) {
            const button = document.querySelector(props.navigation.nextEl);
            button?.classList?.add("invisible");
        } else {
            const button = document.querySelector(props.navigation.nextEl);
            button?.classList?.remove("invisible");
        }

        if (swiper?.isBeginning && props?.navigation?.prevEl) {
            const button = document.querySelector(props.navigation.prevEl);
            button?.classList?.add("invisible");
        } else {
            const button = document.querySelector(props.navigation.prevEl);
            button?.classList?.remove("invisible");
        }

    }

    const handleNext = (e) => {
        e.preventDefault();
        const swiper = getSwiper();
        swiper?.slideNext?.();
        validateButtons();
    }

    const handlePrev = (e) => {
        e.preventDefault();
        const swiper = getSwiper();
        swiper?.slidePrev?.();
        validateButtons();
    }

    validateButtons();

    useEffect(() => {
        if (props.navigation.nextEl) {
            const button = document.querySelector(props.navigation.nextEl);
            button?.addEventListener?.("click", handleNext);
            button?.setAttribute("aria-label", "Next");

        }

        if (props.navigation.prevEl) {
            const button = document.querySelector(props.navigation.prevEl);
            button?.addEventListener?.("click", handlePrev);
            button?.setAttribute("aria-label", "Back");
        }

        const swiper = getSwiper();
        swiper.on("slideChange", validateButtons);
    }, []);



    return (
        <Swiper {...props} navigation={{prevEl: "", nextEl: ""}}>
            {props.children}
        </Swiper>
    )
}