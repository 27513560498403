import Script from "next/script";
import { useApi } from "@context/ApiContext";
import { TitleH4Component } from "@components/TitleH4Component";
import { ParagraphComponent } from "@components/ParagraphComponent";
import { BtnComponent } from "@ui/general/BtnComponent";
import { Icon } from "@iconify/react";
import StyleGeneral from "@scss/general.module.scss";

export default function InstagramEmbedded() {

  const { getTextoBigByKey, getSettingByKey } = useApi();
  
  return (
    <section>
      <div className="container p-instagram">
        <div className="row">
          <div className="col-12 col-lg-6">
            <TitleH4Component styleGroup="roboto color-gris">
              {getTextoBigByKey("enterate-instagram")?.titulo}
            </TitleH4Component>
            <ParagraphComponent
              styleGroup="color-gris"
              html={getTextoBigByKey("enterate-instagram")?.valor}
            ></ParagraphComponent>
          </div>
          <div className="col-12 col-lg-6 text-end">
            <BtnComponent
              clickeable="1"
              target="_blank"
              to={getTextoBigByKey("enterate-instagram")?.link}
              styleGroup=""
            >
              <Icon icon="uil:instagram" />
              <span className="ms-3">
                {getTextoBigByKey("enterate-instagram")?.boton}
              </span>
            </BtnComponent>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-4">
        <iframe
          src={getSettingByKey("iframe-instagram-home")}
          allowtransparency="true"
          className={StyleGeneral.lightwidgetWidget}
          width="100"
          height="100"
          title="lightwidget"
          loading="lazy"
        ></iframe>
        <Script
          strategy="worker"
          src="https://cdn.lightwidget.com/widgets/lightwidget.js"
        />
      </div>
    </section>
  );
}
