import Style from "../scss/text.module.scss";

export function HiddenH1Component(props) {
  const { html, styleGroup, children } = props;
  return (
    <h1 className={`${Style.h1_hidden} ${styleGroup}`}>
      {html ? (
        <span dangerouslySetInnerHTML={{ __html: html }}></span>
      ) : (
        children
      )}
    </h1>
  );
}
