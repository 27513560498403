"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import Banner from "@ui/general/Banner";
import { useApi } from "@context/ApiContext";
import StyleGeneral from "@scss/general.module.scss";
import { url_image } from "@api/ProyectoApi";
import ClientWrapper from "@/ClienteWrapper";

export default function Component(props) {
  return (
    <ClientWrapper>
      <BannerHome {...props} />
    </ClientWrapper>
  );
}

function BannerHome({ slideData }) {
  const { getTranslation } = useApi();
  return (
    <>
      {slideData && slideData?.length > 0 ? (
        <section>
          <div style={{minHeight:'275px'}}>
            <Swiper
              modules={[Pagination, Autoplay]}
              spaceBetween={10}
              slidesPerView={1}
              loop={true}
              pagination={{ clickable: true }}
              speed={2000}
              autoplay={{ delay: 6000, disableOnInteraction: true }}
              lazy={true}
            >
              {slideData?.map((item, index) => (
                <SwiperSlide key={index}>
                  <Banner
                    pictureSrc={url_image + item.image}
                    pictureSrcTablet={url_image + item.imagetablet}
                    pictureSrcMobile={url_image + item.imagemobile}
                    pictureStyle={`${StyleGeneral.imgPortada}`}
                    alt={getTranslation(item, "titulo")}
                    styleGroup="col-lg-12  my-4"
                    secondarySlider
                    bannercontainerStyle=""
                    titleHead={getTranslation(item, "titulo")}
                    titleHeadStyle="text-center text-md-start color-white fw-bold mb-2 mb-lg-1 lh"
                    buttonlink={getTranslation(item, "link")}
                    textbutton={getTranslation(item, "boton")}
                    containerButtonStyle="col-lg-10 text-center text-md-start"
                    lazy={index!==0}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}
