import Image from "next/image";
import { url_image } from "@api/ProyectoApi";
import { useApi } from "@context/ApiContext";
import { TitleH2Component } from "@components/TitleH2Component";
import { ParagraphComponent } from "@components/ParagraphComponent";
import { BtnComponent } from "@ui/general/BtnComponent";
import StyleHome from "@scss/home.module.scss";
import StyleBtn from "@scss/btn.module.scss";

export default function Invest({ data, show = false }) {
  const { getTranslation } = useApi();

  if (!data || !show) {
    return <></>;
  }

  return (
    <section>
      <div className={`container position-relative ${StyleHome.fondo}`}>
        <div className="row">
          <div className="col-12 col-lg-7">
            <Image
              src={url_image + data?.image}
              alt="Compra desde el exterior"
              fill
              priority={true}
              className="position-relative"
              loading="eager"
            />
          </div>
          <div className="col-12 col-lg-5 position-relative d-flex align-items-center">
            <div className="p-2">
              <TitleH2Component styleGroup="roboto">
                {getTranslation(data, "titulo")}
              </TitleH2Component>
              <ParagraphComponent
                html={getTranslation(data, "descripcion")}
              ></ParagraphComponent>
              <div className="text-center text-lg-start">
                <BtnComponent
                  id="btn-saberMas"
                  styleGroup={StyleBtn.btnSecundary}
                  to={getTranslation(data, "link")}
                >
                  {getTranslation(data, "boton")}
                </BtnComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
